<template>
    <!-- Footer Area Start Here -->
    <footer class="footer-wrap-layout1">
        <div class="footer-top-wrap-layout1">
            <div class="container">
                <div class="row">
                    <div class="col-lg-4">
                        <div class="footer-box-layout1">
                            <div class="footer-logo">
                                <router-link to="/" class="temp-logo text-white" style="font-weight: bold;font-size: 20px">
                                    <img src="icons/logo.png" alt="logo" style="width: 40px;height: 40px;margin-right: 10px" class="img-fluid">
                                    NORB
                                </router-link>
                            </div>
                            <p>
                                Temos uma equipe preparada para recrutar<br>
                                e selecionar os melhores profissionais para sua empresa.
                            </p>
                            <ul class="footer-social">
                                <li><a href="https://web.facebook.com/Norb-Consulting-867907223357862" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                                <li><a href="https://www.instagram.com/norb.co.mz/?hl=pt" target="_blank"><i class="fab fa-instagram"></i></a></li>
                                <li><a href="https://www.linkedin.com/company/norb-consulting/" target="_blank"><i class="fab fa-linkedin-in"></i></a></li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-lg-5">
                        <div class="footer-box-layout1">
                            <div class="footer-title">
                                <h3>Soluções</h3>
                            </div>
                            <div class="footer-menu-box">
                                <ul class="footer-menu-list">
                                    <li>
                                        <router-link to="/services">Recrutamento e Seleção</router-link>
                                    </li>
                                    <li>
                                        <router-link to="/services">Trabalho temporário</router-link>
                                    </li>
                                    <li>
                                        <router-link to="/services">Assessment e testes psicotécnicos</router-link>
                                    </li>
                                    <li>
                                        <router-link to="/services">Formação</router-link>
                                    </li>
                                </ul>
                                <ul class="footer-menu-list">
                                    <li>
                                        <router-link to="/">Início</router-link>
                                    </li>
                                    <li>
                                        <router-link to="/contact">Contactos</router-link>
                                    </li>
                                    <li>
                                        <router-link to="/blog">Blog</router-link>
                                    </li>
                                    <li>
                                        <router-link to="/about">Sobre</router-link>
                                    </li>
                                    <!--<li>-->
                                        <!--<a href="#">Nossa equipe</a>-->
                                    <!--</li>-->
                                </ul>
                            </div>
                        </div>
                    </div>
                    <!--<div class="col-lg-3">-->
                        <!--<div class="footer-box-layout1">-->
                            <!--<div class="footer-title">-->
                                <!--<h3>Get A Quote</h3>-->
                            <!--</div>-->
                            <!--<div class="footer-form-box">-->
                                <!--<form class="contact-form-box">-->
                                    <!--<div class="row">-->
                                        <!--<div class="col-12 form-group">-->
                                            <!--<input type="email" placeholder="Your E-mail *" class="form-control" name="name" data-error="Name field is required" required>-->
                                            <!--<div class="help-block with-errors"></div>-->
                                        <!--</div>-->
                                        <!--<div class="col-12 form-group">-->
                                                <!--<textarea placeholder="Your Message *" class="textarea form-control" name="message" id="form-message" rows="5" cols="20"-->
                                                          <!--data-error="Message field is required" required></textarea>-->
                                            <!--<div class="help-block with-errors"></div>-->
                                        <!--</div>-->
                                        <!--<div class="col-12 form-group">-->
                                            <!--<button type="submit" class="fw-btn-fill bg-white text-accent">Send Message<i class="fas fa-arrow-circle-right"></i></button>-->
                                        <!--</div>-->
                                    <!--</div>-->
                                    <!--<div class="form-response"></div>-->
                                <!--</form>-->
                            <!--</div>-->
                        <!--</div>-->
                    <!--</div>-->
                </div>
            </div>
        </div>
        <div class="footer-bottom-wrap-layout1">
            <div class="copyright">Copyright © 2019 NORB by <a target="_blank" href="https://www.explicador.co.mz" rel="nofollow">explicador.co.mz.</a> Todos direitos reservados.</div>
        </div>
    </footer>
    <!-- Footer Area End Here -->
</template>

<script>
    export default {
        name: "FooterComponent"
    }
</script>

<style scoped>

</style>