<template>
    <!-- Case Study Area Start Here -->
    <section class="case-study-wrap-layout1">
        <div class="container">
            <div class="heading-layout1">
                <h2>Recent Case Studies</h2>
                <p>To become an able and successful man in any profession, three things are necessary,
                    nature, three things are necessary study and practice. </p>
            </div>
            <div class="row">
                <div class="col-lg-4 col-md-6 col-12">
                    <div class="case-study-box-layout1">
                        <div class="item-img">
                            <img src="img/casestudy/case.jpg" alt="Case Study">
                        </div>
                        <div class="item-content">
                            <h3 class="item-title"> <a href="single-case-study1.html">Social Marketing</a></h3>
                            <p>There is no better way of exercising the imagination than the study of law. No poet ever interpreted nature...</p>
                            <a href="single-case-study1.html" class="item-btn">Read More<i class="fas fa-arrow-circle-right"></i></a>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-12">
                    <div class="case-study-box-layout1">
                        <div class="item-img">
                            <img src="img/casestudy/case1.jpg" alt="Case Study">
                        </div>
                        <div class="item-content">
                            <h3 class="item-title"> <a href="single-case-study1.html">Content Strategy</a></h3>
                            <p>There is no better way of exercising the imagination than the study of law. No poet ever interpreted nature...</p>
                            <a href="single-case-study1.html" class="item-btn">Read More<i class="fas fa-arrow-circle-right"></i></a>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-12">
                    <div class="case-study-box-layout1">
                        <div class="item-img">
                            <img src="img/casestudy/case2.jpg" alt="Case Study">
                        </div>
                        <div class="item-content">
                            <h3 class="item-title"> <a href="single-case-study1.html">Email Marketing</a></h3>
                            <p>There is no better way of exercising the imagination than the study of law. No poet ever interpreted nature...</p>
                            <a href="single-case-study1.html" class="item-btn">Read More<i class="fas fa-arrow-circle-right"></i></a>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-12">
                    <div class="case-study-box-layout1">
                        <div class="item-img">
                            <img src="img/casestudy/case3.jpg" alt="Case Study">
                        </div>
                        <div class="item-content">
                            <h3 class="item-title"> <a href="single-case-study1.html">Data Analysis</a></h3>
                            <p>For it is the duty of an astronomer to compose the history
                                of the celestial motions through careful.</p>
                            <a href="single-case-study1.html" class="item-btn">Read More<i class="fas fa-arrow-circle-right"></i></a>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-12">
                    <div class="case-study-box-layout1">
                        <div class="item-img">
                            <img src="img/casestudy/case4.jpg" alt="Case Study">
                        </div>
                        <div class="item-content">
                            <h3 class="item-title"> <a href="single-case-study1.html">Delivering Results</a></h3>
                            <p>People who make the choice to study, work hard or do whatever
                                they endeavor is to give it the max on themselves...</p>
                            <a href="single-case-study1.html" class="item-btn">Read More<i class="fas fa-arrow-circle-right"></i></a>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-12">
                    <div class="case-study-box-layout1">
                        <div class="item-img">
                            <img src="img/casestudy/case5.jpg" alt="Case Study">
                        </div>
                        <div class="item-content">
                            <h3 class="item-title"> <a href="single-case-study1.html">Top SEO Experts</a></h3>
                            <p>You learn to speak by speaking, to study by studying, to run by running,
                                to work by working; in just the same way...</p>
                            <a href="single-case-study1.html" class="item-btn">Read More<i class="fas fa-arrow-circle-right"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- Case Study Area End Here -->
</template>

<script>
    export default {
        name: "CaseAreaComponent"
    }
</script>

<style scoped>

</style>