<template>
    <!-- Services Area Start Here -->
    <section class="service-wrap-layout1">
        <div class="container">
            <div class="heading-layout1">
                <h2>Nossos serviços</h2>
                <p>
                    Somos especializados em consultoria, prestamos um serviço de excelência nas áreas de recrutamento e selecção, fazemos a avaliação, prospecção, atracção, selecção e a contratação de profissionais experientes, com foco no cliente e na relação custo-benefício.
                    Atuamos para prestar um serviço de excelência nas áreas do recrutamento e selecção, trabalho temporário, outsourcing, serviços especializados, formação e consultoria de recursos humanos.
                </p>
            </div>
            <div class="row">
                <div class="col-lg-4 col-md-6 col-12">
                    <div class="service-box-layout1">
                        <div class="item-icon bg-accent">
                            <i class="flaticon-email"></i>
                        </div>
                        <div class="item-content">
                            <h3 class="item-title"><router-link to="/services">Segurança na contratação</router-link></h3>
                            <p>
                                Avaliação detalhada do currículo e percurso profissional
                            </p>
                            <router-link to="/services" class="item-btn">Saber mais<i class="fas fa-arrow-circle-right"></i></router-link>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-12">
                    <div class="service-box-layout1">
                        <div class="item-icon bg-pink">
                            <i class="flaticon-website"></i>
                        </div>
                        <div class="item-content">
                            <h3 class="item-title"><router-link to="/services">Avaliação psicológica dos candidatos</router-link></h3>
                            <p>
                                é essencial para alcançar uma contratação de sucesso
                            </p>
                            <router-link to="/services" class="item-btn">Saber mais<i class="fas fa-arrow-circle-right"></i></router-link>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-12">
                    <div class="service-box-layout1">
                        <div class="item-icon bg-dark-primary">
                            <i class="flaticon-note"></i>
                        </div>
                        <div class="item-content">
                            <h3 class="item-title"><router-link to="/services">Relatórios de Avaliação</router-link></h3>
                            <p>
                                Envio do relatório dos perfis ajustados ao que foi definido pelo cliente
                            </p>
                            <router-link to="/services" class="item-btn">Saber mais<i class="fas fa-arrow-circle-right"></i></router-link>
                        </div>
                    </div>
                </div>


            </div>
        </div>
    </section>
    <!-- Services Area End Here -->
</template>

<script>
    export default {
        name: "ServiceComponent"
    }
</script>

<style scoped>

</style>