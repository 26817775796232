<template>
    <!-- Pricing Plan Area Start Here -->
    <section class="pricing-wrap-layout1">
        <div class="container">
            <div class="heading-layout1">
                <h2>Our Pricing Plan</h2>
                <p>The price of success is hard work, dedication to the job at hand,
                    and the determination that whether we win or lose, we have applied the
                    best of ourselves to the task at hand.</p>
            </div>
            <div class="row">
                <div class="col-lg-4">
                    <div class="pricing-box-layout1">
                        <div class="item-heading">
                            <h3 class="item-title">Silver Package</h3>
                            <div class="item-price">$39.99</div>
                            <div class="item-duration">/ Per Month</div>
                        </div>
                        <ul class="item-features">
                            <li>1 E-mail Address</li>
                            <li>10 Space Users</li>
                            <li>15 GB Space</li>
                            <li>Unlimtied Bandwit</li>
                            <li>Support Reports</li>
                            <li>15GB Uploads</li>
                        </ul>
                        <a href="#" class="ghost-btn-1 text-c-primary border-c-primary">BUY NOW<i class="fas fa-arrow-circle-right"></i></a>
                        <div class="item-curved">
                            <svg
                                    width="100%" height="100%" viewBox="0 0 361.000000 228.000000"
                                    preserveAspectRatio="none" class="svg-shape">
                                <g transform="translate(0.000000,228.000000) scale(0.100000,-0.100000)"
                                   stroke="none" class="svg-color">
                                    <path d="M0 1162 l0 -1119 114 58 c212 109 419 179 652 220 112 21 162 24 389
                                        24 361 0 542 -26 1165 -170 598 -138 841 -175 1148 -175 l142 0 0 1140 0 1140
                                        -1805 0 -1805 0 0 -1118z"/>
                                </g>
                            </svg>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="pricing-box-layout1">
                        <div class="item-heading">
                            <h3 class="item-title">Golden Package</h3>
                            <div class="item-price">$59.99</div>
                            <div class="item-duration">/ Per Month</div>
                        </div>
                        <ul class="item-features">
                            <li>3 E-mail Address</li>
                            <li>10 Space Users</li>
                            <li>15 GB Space</li>
                            <li>Unlimtied Bandwit</li>
                            <li>Support Reports</li>
                            <li>15GB Uploads</li>
                        </ul>
                        <a href="#" class="ghost-btn-1 text-c-primary border-c-primary">BUY NOW<i class="fas fa-arrow-circle-right"></i></a>
                        <div class="item-curved">
                            <svg
                                    width="100%" height="100%" viewBox="0 0 361.000000 228.000000"
                                    preserveAspectRatio="none" class="svg-shape">
                                <g transform="translate(0.000000,228.000000) scale(0.100000,-0.100000)"
                                   stroke="none" class="svg-color">
                                    <path d="M0 1162 l0 -1119 114 58 c212 109 419 179 652 220 112 21 162 24 389
                                        24 361 0 542 -26 1165 -170 598 -138 841 -175 1148 -175 l142 0 0 1140 0 1140
                                        -1805 0 -1805 0 0 -1118z"/>
                                </g>
                            </svg>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="pricing-box-layout1">
                        <div class="item-heading">
                            <h3 class="item-title">Platinum Package</h3>
                            <div class="item-price">$89.99</div>
                            <div class="item-duration">/ Per Month</div>
                        </div>
                        <ul class="item-features">
                            <li>10 E-mail Address</li>
                            <li>10 Space Users</li>
                            <li>15 GB Space</li>
                            <li>Unlimtied Bandwit</li>
                            <li>Support Reports</li>
                            <li>15GB Uploads</li>
                        </ul>
                        <a href="#" class="ghost-btn-1 text-c-primary border-c-primary">BUY NOW<i class="fas fa-arrow-circle-right"></i></a>
                        <div class="item-curved">
                            <svg
                                    width="100%" height="100%" viewBox="0 0 361.000000 228.000000"
                                    preserveAspectRatio="none" class="svg-shape">
                                <g transform="translate(0.000000,228.000000) scale(0.100000,-0.100000)"
                                   stroke="none" class="svg-color">
                                    <path d="M0 1162 l0 -1119 114 58 c212 109 419 179 652 220 112 21 162 24 389
                                        24 361 0 542 -26 1165 -170 598 -138 841 -175 1148 -175 l142 0 0 1140 0 1140
                                        -1805 0 -1805 0 0 -1118z"/>
                                </g>
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- Pricing Plan Area End Here -->
</template>

<script>
    export default {
        name: "PrincingComponent"
    }
</script>

<style scoped>

</style>