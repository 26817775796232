<template>
    <!-- Testimonial Area Start Here -->
    <section class="testimonial-wrap-layout1">
        <div class="container">
            <div class="row">
                <div class="col-lg-6">
                    <div class="testimonial-box-layout1">
                        <img src="img/testimonial/testimonial1.jpg" alt="testimonial">
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="testimonial-box-layout2">
                        <div class="heading-layout1 text-lg-left">
                            <h2>Feedback dos nossos clientes</h2>
                        </div>
                        <div class="rc-carousel dot-control-layout1" data-loop="true" data-items="10" data-margin="50"
                             data-autoplay="false" data-autoplay-timeout="5000" data-smart-speed="2000" data-dots="true"
                             data-nav="false" data-nav-speed="false" data-r-x-small="1" data-r-x-small-nav="false"
                             data-r-x-small-dots="true" data-r-x-medium="1" data-r-x-medium-nav="false" data-r-x-medium-dots="true"
                             data-r-small="1" data-r-small-nav="false" data-r-small-dots="true" data-r-medium="1"
                             data-r-medium-nav="false" data-r-medium-dots="true" data-r-large="1" data-r-large-nav="false"
                             data-r-large-dots="true" data-r-extra-large="1" data-r-extra-large-nav="false"
                             data-r-extra-large-dots="true">
                            <div class="single-testimonial">
                                <div class="item-paragraph">“ A melhor experiência possível, são super bons. Muito obrigada pela oportunidade que me proporcionaram de poder estar numa empresa que acredita no meu potencial! ”</div>
                                <h3 class="item-title">Candidata</h3>
                                <!--
                                <div class="item-subtitle">Seo &amp; Business Analyst</div>
                                -->
                            </div>
                            <div class="single-testimonial">
                                <div class="item-paragraph">“ Durante todo o meu processo de candidatura e de posterior selecção sempre estiveram
disponíveis a ajudar na busca de melhor emprego e solução para mim como candidato,
sempre esclarecem qualquer tipo de dúvida ”</div>
                                <h3 class="item-title">Candidata</h3>
                            </div>
                            <div class="single-testimonial">
                                <div class="item-paragraph">“ Já procurei os serviços da empresa por 2 vezes e tenho ficado sempre satisfeito com os
profissionais que me são fornecidos. É uma empresa de confiança e que se preocupa em ir de
encontro com as necessidades específicas do cliente. Parabéns e muito obrigado pelo serviço ”</div>
                                <h3 class="item-title">Cliente</h3>
                            </div>
                            <div class="single-testimonial">
                                <div class="item-paragraph">“ Só posso elogiar o trabalho sempre prestativo da empresa. Fizeram uma excelente selecção
dos candidatos, adequação ao briefing e gestão de empregabilidade dos colocados.
Recomendo! ”</div>
                                <h3 class="item-title">Cliente</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- Testimonial Area End Here -->
</template>

<script>
    export default {
        name: "TestemonialComponent"
    }
</script>

<style scoped>

</style>