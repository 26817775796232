<template>
    <!-- Slider Area Start Here -->
    <div class="slider-area slider-layout1 mg-t--100">
        <div class="bend niceties preview-1" style="margin-top: 100px">
            <div id="ensign-nivoslider-1" class="slides">
                <img src="img/banners/1.png" alt="slider" title="#slider-direction-1" />
                <img src="img/banners/2.png" alt="slider" title="#slider-direction-2" />
                <!--<img src="img/slider/slide1-3.jpg" alt="slider" title="#slider-direction-3" />-->
            </div>
            <!--<div id="slider-direction-1" class="t-cn slider-direction">-->
                <!--<div class="slider-content s-tb slide-1">-->
                    <!--<div class="text-left title-container s-tb-c">-->
                        <!--<div class="container">-->
                            <!--<div class="slider-sub-text">Equipe eficaz e que se adapta a todos cenários</div>-->
                            <!--<div class="slider-big-text">Equipe profissional de vendas</div>-->
                            <!--<div class="slider-paragraph">-->
                                <!--O objectivo da nossa empresa é aumentar as vendas da sua organização com soluções flexíveis de recursos humanos (RH)-->
                            <!--</div>-->
                            <!--<div class="slider-btn-area">-->
                                <!--<a href="#" class="item-btn-accent">Saber mais-->
                                    <!--<i class="fas fa-arrow-circle-right"></i>-->
                                <!--</a>-->
                            <!--</div>-->
                        <!--</div>-->
                    <!--</div>-->
                <!--</div>-->
            <!--</div>-->
            <!--<div id="slider-direction-2" class="t-cn slider-direction">-->
                <!--<div class="slider-content s-tb slide-2">-->
                    <!--<div class="text-left title-container s-tb-c">-->
                        <!--<div class="container">-->
                            <!--<div class="slider-sub-text">Não precisa de muitos colaboradores</div>-->
                            <!--<div class="slider-big-text">Contracte mediante a sua realidade de negóricio</div>-->
                            <!--<div class="slider-paragraph">-->
                                <!--Existem projectos que exigem um esforço da equipa de vendas ou especialistas num determinado contexto comercial.-->
                            <!--</div>-->
                            <!--<div class="slider-btn-area">-->
                                <!--<a href="#" class="item-btn-accent">Saber mais-->
                                    <!--<i class="fas fa-arrow-circle-right"></i>-->
                                <!--</a>-->
                            <!--</div>-->
                        <!--</div>-->
                    <!--</div>-->
                <!--</div>-->
            <!--</div>-->
            <div id="slider-direction-3" class="t-cn slider-direction">
                <div class="slider-content s-tb slide-3">
                    <div class="text-left title-container s-tb-c">
                        <div class="container">
                            <div class="slider-sub-text">Confie esse trabalho a empresa certa</div>
                            <div class="slider-big-text">Garanta qualidade no recrutamento</div>
                            <div class="slider-paragraph">
                                Os profissionais recrutados e selecionados através da nossa empresa, apresentam proposta de valor para cada um dos seus pontenciais clientes.
                            </div>
                            <div class="slider-btn-area">
                                <a href="#" class="item-btn-accent">Saber mais
                                    <i class="fas fa-arrow-circle-right"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Slider Area End Here -->
</template>

<script>
    export default {
        name: "SliderAreaComponent",
        mounted() {
            $('#ensign-nivoslider-1').nivoSlider({

                effect: 'sliceDownLeft', // Specify sets like: 'sliceDownLeft sliceUp sliceUpLeft sliceUpDown sliceUpDownLeft fold fade random slideInRight slideInLeft boxRandom boxRain boxRainReverse boxRainGrow boxRainGrowReverse'
                slices: 15, // For slice animations
                boxCols: 8, // For box animations
                boxRows: 4, // For box animations
                animSpeed: 1000, // Slide transition speed
                pauseTime: 9000, // How long each slide will show
                startSlide: 0, // Set starting Slide (0 index)
                directionNav: true, // Next & Prev navigation
                controlNav: true, // 1,2,3... navigation
                controlNavThumbs: false, // Use thumbnails for Control Nav
                pauseOnHover: false, // Stop animation while hovering
                manualAdvance: false, // Force manual transitions
                prevText: 'Prev', // Prev directionNav text
                nextText: 'Next', // Next directionNav text
                randomStart: false, // Start on a random slide
                beforeChange: function() {}, // Triggers before a slide transition
                afterChange: function() {}, // Triggers after a slide transition
                slideshowEnd: function() {}, // Triggers after all slides have been shown
                lastSlide: function() {}, // Triggers when last slide is shown
                afterLoad: function() {} // Triggers when slider has loaded

                /*effect: 'fade',

                slices: 15,

                boxCols: 8,

                boxRows: 4,

                animSpeed: 500,

                pauseTime: 5000,

                startSlide: 0,

                directionNav: true,

                controlNavThumbs: true,

                pauseOnHover: true,

                manualAdvance: false*/

            });
        }
    }
</script>

<style scoped>

</style>