<template>
    <!-- Contact Info Area Start Here -->
    <section class="contact-info-wrap-layout1">
        <div class="container">
            <div class="row">
                <div class="col-md-4 col-12">
                    <div class="contact-info-box-layout1">
                        <div class="media media-none--md">
                            <div class="item-icon">
                                <i class="flaticon-call-answer"></i>
                            </div>
                            <div class="media-body space-md">
                                <h3 class="item-title">(+258) 84 87 62 037</h3>
                                <div class="item-subtitle">Seg a Sexta : 08:00 ás 16:00</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 col-12">
                    <div class="contact-info-box-layout1">
                        <div class="media media-none--md">
                            <div class="item-icon">
                                <i class="flaticon-mail"></i>
                            </div>
                            <div class="media-body space-md">
                                <h3 class="item-title">comercial@norb.co.mz</h3>
                                <div class="item-subtitle">Disponibilidade imediata</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 col-12">
                    <div class="contact-info-box-layout1">
                        <div class="media media-none--md">
                            <div class="item-icon">
                                <i class="flaticon-maps-and-flags"></i>
                            </div>
                            <div class="media-body space-md">
                                <h3 class="item-title">Maputo, Mozambique</h3>
                                <div class="item-subtitle">José Mateus, Nr. 185, Polana Cimento A</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- Contact Info Area End Here -->
</template>

<script>
    export default {
        name: "ContactComponent"
    }
</script>

<style scoped>

</style>