<template>
    <!-- Header Area Start Here -->
    <header class="header">
        <div v-if="!canHideTopSmallBar" id="header-topbar" class="bg-primarytext">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6">
                        <div class="header-topbar-box">
                            <ul class="header-contact">
                                <li><i class="fas fa-phone-volume"></i>123-124-44227</li>
                                <li><i class="fas fa-envelope"></i>Optimax@gmail.com</li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-lg-6 d-flex justify-content-end">
                        <div class="header-topbar-box">
                            <ul class="header-social-item">
                                <li class="lang-select">
                                    <div class="dropdown">
                                        <button class="dropdown-btn dropdown-toggle" type="button" data-toggle="dropdown"
                                                aria-haspopup="true" aria-expanded="false"><i class="fas fa-globe-asia"></i>English</button>
                                        <div class="dropdown-menu dropdown-menu-right">
                                            <a class="dropdown-item" href="#">English</a>
                                            <a class="dropdown-item" href="#">Russian</a>
                                            <a class="dropdown-item" href="#">French</a>
                                            <a class="dropdown-item" href="#">Turkish</a>
                                        </div>
                                    </div>
                                </li>
                                <li class="social-icon">
                                    <a href="#"><i class="fab fa-facebook-f"></i></a>
                                    <a href="#"><i class="fab fa-twitter"></i></a>
                                    <a href="#"><i class="fab fa-linkedin-in"></i></a>
                                    <a href="#"><i class="fab fa-instagram"></i></a>
                                    <a href="#"><i class="fab fa-google-plus-g"></i></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div id="rt-sticky-placeholder"></div>

        <div id="header-menu" class="header-menu menu-layout1 pd-x-45">
            <div class="container-fluid">
                <div class="row d-flex align-items-center header-container">
                    <div class="col-xl-2 col-lg-2">
                        <div class="logo-area">
                            <router-link to="/" class="temp-logo text-black" style="font-weight: bold">
                                <img src="icons/logo.png" alt="logo" style="width: 40px;height: 40px;margin-right: 10px" class="img-fluid">
                                NORB
                            </router-link>
                            <router-link to="/" class="sticky-logo text-white" style="font-weight: bold">
                                <img src="icons/logo.png" alt="logo" style="width: 40px;height:40px;margin-right: 10px;border-radius: 10px" class="img-fluid  bg-white">
                                NORB
                            </router-link>
                            <!--<a href="index.html" class="sticky-logo">-->
                                <!--<img src="img/sticky-logo1.png" alt="logo" class="img-fluid">-->
                            <!--</a>-->
                        </div>
                    </div>
                    <div class="col-xl-7 col-lg-8 d-flex justify-content-center possition-static option-links">
                        <nav id="dropdown" class="template-main-menu">
                            <ul>
                                <li>
                                    <router-link to="/">INÍCIO</router-link>
                                </li>
                                <li>
                                    <router-link to="/about">SOBRE</router-link>
                                </li>
                                <li>
                                    <router-link to="/services">SERVIÇOS</router-link>
                                </li>
                                <li>
                                    <router-link to="/blog">BLOG</router-link>
                                </li>
                                <li>
                                    <RouterLink to="/contact">CONTACTOS</RouterLink>
                                </li>
                            </ul>
                        </nav>
                    </div>
                    <div class="col-xl-3 col-lg-2 d-flex justify-content-end">
                        <div class="header-action-layout1">
                            <ul>
                                <li class="offcanvas-menu-trigger-wrap">
                                    <button @click="$emit('canToggleOffCanvas')" type="button" class="offcanvas-menu-btn menu-status-open ">
                                            <span class="btn-icon-wrap">
                                                <span></span>
                                                <span></span>
                                                <span></span>
                                            </span>
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <!--<canvas-component />-->

    </header>
    <!-- Header Area End Here -->
</template>

<script>
    import CanvasComponent from "./CanvasComponent";
    export default {
        name: "HeaderComponent",
        components: {CanvasComponent},
        props: ['canHideTopSmallBar']
    }
</script>

<style scoped>

</style>