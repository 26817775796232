import Vue from 'vue'
import Vuex from 'vuex'

import moment from 'moment'

import { dbNorbUserContact } from '../packages/firebase'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    blogs: [
      {
        id: 1,
        title: 'Quatro Formas de Receber um Novo Colaborador na sua Equipa',
        img: 'img/blog/blog4.jpg',
        link: '/blog/1',
        smallDesc: 'Receber uma nova contratação na equipa é uma oportunidade empolgante para todos os envolvidos.',
        author_id: 1
      },
      {
        id: 2,
        title: 'Dicas para manter a Produtividade a Trabalhar a Partir de Casa',
        img: 'img/blog/blog19.jpg',
        link: '/blog/2',
        smallDesc: 'Com base na experiência dos nossos colaboradores, seguem-se algumas dicas já experimentadas e...',
        author_id: 1
      },
      {
        id: 3,
        title: 'Dicas para elaborar um óptimo CV',
        img: 'img/blog/blog22.jpg',
        link: '/blog/3',
        smallDesc: 'Um excelente CV ainda é um dos maiores factores para conseguir um bom trabalho. Afinal de contas, apresenta aos recrutadores e...',
        author_id: 1
      },
      {
        id: 4,
        title: 'COMO REDUZIR O CUSTO DO PROCESSO DE RECRUTAMENTO E SELEÇÃO?',
        img: 'img/blog/blog5.jpg',
        author_id: 1,
        smallDesc: 'Em um mercado cada vez mais competitivo, contratar profissionais de qualidade e que atendam às expectativas da empresa é imprescindível. Mas afinal ...',
        link: "blog/4"
      },
      {
        id: 5,
        title: 'POR QUE AS EMPRESAS GASTAM MUITO COM O RECRUTAMENTO?',
        img: 'img/blog/blog6.jpg',
        author_id: 1,
        smallDesc: 'O processo de recrutamento e seleção exige o investimento em divulgações, dinâmicas ...',
        link: "blog/5"
      },
      {
        id: 6,
        title: 'COMO REDUZIR OS CUSTOS COM O RECRUTAMENTO E SELEÇÃO?',
        img: 'img/blog/blog7.jpg',
        author_id: 1,
        smallDesc: 'Ter processos seletivos bem planejados para buscar talentos no mercado com métodos otimizados e ...',
        link: "blog/6"
      },
      {
        id: 7,
        title: 'SOFT SKILLS E HARD SKILLS: VEJA DE QUAIS HABILIDADES SUA EMPRESA PRECISA',
        img: 'img/blog/blog8.jpg',
        author_id: 1,
        smallDesc: 'Não é fácil encontrar metais preciosos como o ouro e a prata. Mesmo quando são identificados, é preciso ...',
        link: "blog/7"
      },
      {
        id: 9,
        title: 'O QUE SÃO AS SOFT SKILLS E HARD SKILLS?',
        img: 'img/blog/blog10.jpg',
        author_id: 1,
        smallDesc: 'As habilidades hard skills são antigas conhecidas dos recrutadores, pois aparecem de forma recorrente ...',
        link: "blog/9"
      },
      {
        id: 10,
        title: 'QUAIS SÃO AS HABILIDADES MAIS COBIÇADAS PELAS EMPRESAS MODERNAS?',
        img: 'img/blog/blog11.jpg',
        smallDesc: 'Um estudo apresentado pela Global Challenge Insight Report mostrou que, na era da Inteligência ...',
        author_id: 1,
        link: "blog/10"
      },
      {
        id: 11,
        title: 'COMO IDENTIFICAR AS HABILIDADES EM UM PROCESSO SELETIVO?',
        img: 'img/blog/blog12.jpg',
        author_id: 1,
        smallDesc: 'Atualmente, existem ferramentas virtuais que auxiliam no reconhecimento de competências ...',
        link: "blog/11"
      },
      {
        id: 12,
        title: 'VOCÊ SABE COMO FUNCIONA O RECRUTAMENTO E SELEÇÃO POR COMPETÊNCIA?',
        img: 'img/blog/blog13.jpg',
        author_id: 1,
        smallDesc: 'O recrutamento é um dos processos mais importantes das empresas de todo o mundo. É um ...',
        link: "blog/12"
      },
      {
        id: 13,
        title: 'PROCESSO SELETIVO REMOTO: CONTRATE O PROFISSIONAL IDEAL A DISTÂNCIA',
        img: 'img/blog/blog14.jpg',
        author_id: 1,
        smallDesc: 'O processo seletivo remoto se popularizou há algum tempo devido ao avanço das tecnologias ...',
        link: "blog/13"
      },
      {
        id: 14,
        title: 'INTEGRAR PARA INFORMAR E MOTIVAR NOVOS COLABORADORES',
        img: 'img/blog/blog15.jpg',
        author_id: 1,
        smallDesc: 'A integração de funcionários, também chamada de ambientação, é um processo fundamental ao fazer novas contratações ...',
        link: "blog/14"
      },
      {
        id: 15,
        title: 'O QUE É UM HEADHUNTER E COMO ELE PODE AJUDAR A EMPRESA?',
        img: 'img/blog/blog16.jpg',
        author_id: 1,
        smallDesc: 'Você sabe o que é um headhunter e qual a sua importância para as empresas? Esses profissionais podem ajudar a ...',
        link: "blog/15"
      },
      {
        id: 16,
        title: 'FIQUE POR DENTRO DOS PRINCIPAIS TIPOS DE ENTREVISTA DE EMPREGO',
        img: 'img/blog/blog17.jpg',
        author_id: 1,
        smallDesc: 'Contratar um novo colaborador é um grande desafio para os profissionais de recursos humanos. O processo de recrutamento e seleção deve ser planejado passo ...',
        link: "blog/16"
      },
      {
        id: 17,
        title: 'ENTENDA O OBJETIVO DAS PERGUNTAS PARA ENTREVISTA DE DEMISSÃO',
        img: 'img/blog/blog18.jpg',
        author_id: 1,
        smallDesc: 'Você sabe como direcionar as perguntas para entrevista de desligamento? Essa é uma ferramenta importante, utilizada com um trabalhador que ...',
        link: "blog/17"
      },
      {
        id: 18,
        title: 'ENTREVISTA DE COMPETÊNCIA: UMA EXCELENTE FERRAMENTA A SE TRABALHAR!',
        img: 'img/blog/blog19.jpg',
        author_id: 1,
        smallDesc: 'A entrevista de competência é uma metodologia que pode ser usada para otimizar os processos seletivos ...',
        link: "blog/18"
      },
      {
        id: 19,
        title: 'QUAIS SÃO OS PRINCIPAIS TIPOS DE TESTES PSICOLÓGICOS?',
        img: 'img/blog/blog20.jpg',
        author_id: 1,
        smallDesc: 'Os processos de recrutamento e seleção costumam ser lentos e caros. Para contratar um profissional realmente alinhado ao cargo e manter um bom índice de retenção ...',
        link: "blog/19"
      },
      {
        id: 20,
        title: 'É HORA PARA UMA NOVA CONTRATAÇÃO?',
        img: 'img/blog/blog21.jpg',
        author_id: 1,
        smallDesc: 'A decisão do momento ideal para contratar um novo funcionário (ou mais) pode ser um verdadeiro dilema para empresários, principalmente proprietários ...',
        link: "blog/20"
      },
      {
        id: 21,
        title: 'CONDUZINDO BOAS ENTREVISTAS EM PROCESSOS SELETIVOS',
        img: 'img/blog/blog22.jpg',
        author_id: 1,
        smallDesc: 'Uma das etapas mais importantes de um processo seletivo, a entrevista de emprego, é um desafio não apenas para quem está se candidatando a uma vaga ...',
        link: "blog/21"
      },
      {
        id: 22,
        title: 'QUAL O PERFIL IDEAL PARA OS PROFISSIONAIS DA SUA EMPRESA?',
        img: 'img/blog/blog23.jpg',
        author_id: 1,
        smallDesc: 'Entender o perfil de colaborador mais adequado para atuar nas empresas é o passo mais importante para contratações assertivas. Antes da equipe de gestão ...',
        link: "blog/22"
      },
      {
        id: 23,
        title: 'QUESTIONAMENTOS QUE DEVEM SER EVITADOS EM PROCESSOS SELETIVOS',
        img: 'img/blog/blog24.jpg',
        author_id: 1,
        smallDesc: 'As várias etapas que fazem parte de um processo seletivo devem ser encaradas com seriedade por todos os envolvidos, já que possui vários desafios não ...',
        link: "blog/23"
      }
    ],

    authors: [
      {
        id: 1,
        name: "NORB",
        description: "Somos especializados em consultoria, prestamos um serviço de excelência nas áreas de recrutamento e selecção, fazemos a avaliação, prospecção, atracção, selecção e a contratação de profissionais experientes, com foco no cliente e na relação custo-benefício. Atuamos para prestar um serviço de excelência nas áreas do recrutamento e selecção, trabalho temporário, outsourcing, serviços especializados, formação e consultoria de recursos humanos."
      }
    ],

    users: [],
    usersRequestLimit: 10,

    loading: false,
    loadingMore: false,

    activePage: 1
  },

  mutations: {
    loading (state, val) {
      state.loading = val
    },

    setLoadingMore (state, val) {
      state.loadingMore = val
    },

    setUsers (state, users) {
      state.users = users
    },

    pushUser (state, user) {
      state.users.push(user)
    },

    setActivePage (state, page) {
      state.activePage = page
    }
  },

  actions: {
    saveData ({ commit }, formData) {
      commit('loading', true)

      let now = Date.now()
      let formatedNow = moment(now).format('YYYY-MM-DD hh:mm')

      formData.created_at = formatedNow

      return dbNorbUserContact.add(formData).then(response => {
        commit('loading', false)

        return true
      })
      .catch(error => {
        console.error("saveData firebase error - ", formData)

        commit('loading', false)

        return false
      })
    },

    getUsers ({ state, commit }) {
      console.log('getting users ...')

      dbNorbUserContact.limit(state.usersRequestLimit).orderBy('created_at', 'desc').get().then(response => {
        let users = []

        response.docChanges().forEach(docChange => {
          let user = docChange.doc.data()
          user.id = docChange.doc.id

          users.push(user)
        })

        commit('setUsers', users)
      })
      .catch(error => {
        console.error('getUsers firebase error - ', error)
      })
    },

    getUserByIdFromFirestore ({}, userId) {
      return dbNorbUserContact
        .doc(userId)
        .get().then(response => {

          if (response.data()) {
            let user = response.data()

            return user
          }

          return null
        }).catch(function(error) {
          console.error('getUserByIdFromFirestore firebase error - ', error)

          return null
        })
    },

    getSnapshotOfTheLast ({}, docId) {
      return dbNorbUserContact
        .doc(docId).get().then(snapshot => {
          return snapshot
        })
        .catch(error => {
          console.error('getMoreData getting lastUser snapshot error - ', error)
        })
    },

    getDataAfterSnapshot ({ state, commit, getters, dispatch }, lastSnapshot) {
      dbNorbUserContact.limit(state.usersRequestLimit).orderBy('created_at', 'desc').startAfter(lastSnapshot).get().then(afterLastSnapshot => {

        let isThereDataAfterLastSnapshot = afterLastSnapshot.docChanges().length > 0

        if (isThereDataAfterLastSnapshot) {

          afterLastSnapshot.docChanges().forEach(docChange => {
            let user = docChange.doc.data()
            user.id = docChange.doc.id

            commit('pushUser', user)
          })

          dispatch('stopLoadingMore')

          return true
        } else {
          console.error('no more data')
          dispatch('stopLoadingMore')
          return null
        }
      })
      .catch(error => {
        console.error('getMoreData startAfter snapshot error - ', error)
        return null
      })
    },

    getMoreData ({ state, commit, dispatch }, lastUserId) {
      dispatch('getSnapshotOfTheLast', lastUserId).then(lastSnapshot => {
        dispatch('getDataAfterSnapshot', lastSnapshot)
      })
    },

    stopLoadingMore ({ commit }) {
      setTimeout(() => {
        commit('setLoadingMore', false)
      }, 2000)
    }
  },

  modules: {
  }
})
