<template>
    <!-- Site Banner Start Here -->
    <section class="banner-wrap-layout1 gradient-primary">
        <div class="container">
            <div class="row">
                <div class="col-lg-6">
                    <div class="banner-box-layout1">
                        <img src="img/figure/banner-img.png" alt="Site Banner">
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="banner-box-layout2">
                        <h2 class="item-title">Nós temos o colaborador que procura <span>entre em contacto agora</span></h2>
                        <router-link to="/contact" class="btn-fill gradient-accent">Contactos<i class="fas fa-arrow-circle-right"></i></router-link>
                    </div>
                </div>
            </div>
        </div>
        <div class="banner-bottom-shape">
            <div class="svg-shape">
                <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                     width="100%" height="100%" viewBox="0 0 1800.000000 250.000000"
                     preserveAspectRatio="none">
                    <g transform="translate(0.000000,250.000000) scale(0.100000,-0.100000)"
                       fill="#041e95" stroke="none">
                        <path d="M5775 1855 c-616 -338 -1123 -615 -1127 -615 -4 0 -602 230 -1329
                        510 -727 280 -1329 510 -1336 510 -8 0 -458 -287 -999 -637 l-984 -638 0 -492
                        0 -493 9000 0 9000 0 0 338 0 339 -112 6 c-62 3 -1008 43 -2103 88 l-1990 83
                        -580 303 c-319 167 -589 303 -601 303 -11 0 -401 -83 -867 -185 -466 -102
                        -854 -185 -862 -185 -8 0 -902 311 -1986 690 -1085 380 -1979 690 -1988 690
                        -9 0 -520 -277 -1136 -615z"/>
                    </g>
                </svg>
            </div>
            <div class="svg-shape">
                <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                     width="100%" height="100%" viewBox="0 0 1850.000000 250.000000"
                     preserveAspectRatio="none">
                    <g transform="translate(0.000000,250.000000) scale(0.100000,-0.100000)"
                       fill="#112fb2" stroke="none">
                        <path d="M7355 1865 c-616 -338 -1123 -615 -1127 -615 -4 0 -602 230 -1329
                        510 -727 280 -1329 510 -1336 510 -14 0 -3503 -2257 -3503 -2266 0 -2 4149 -4
                        9220 -4 l9220 0 0 365 0 364 -32 5 c-18 3 -721 33 -1563 68 l-1530 62 -580
                        303 c-319 167 -589 303 -601 303 -11 0 -401 -83 -867 -185 -466 -102 -854
                        -185 -862 -185 -8 0 -902 311 -1986 690 -1085 380 -1979 690 -1988 690 -9 0
                        -520 -277 -1136 -615z"/>
                    </g>
                </svg>
            </div>
        </div>
    </section>
    <!-- Site Banner End Here -->
</template>

<script>
    export default {
        name: "BannerComponent"
    }
</script>

<style scoped>

</style>