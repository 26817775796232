<template>
    <!-- Blog Area Start Here -->
    <section class="blog-wrap-layout1">
        <div class="container">
            <div class="heading-layout1">
                <h2>Últimas notícias</h2>
                <p>
                    Saiba das últimas novidades no nosso blog de notícias. Encontrará várias actualizações da nossa empresa e dos nossos parceiros.
                </p>
            </div>
            <div class="row">
                <div class="col-lg-4 col-md-6 col-12">
                    <div class="blog-box-layout1">
                        <div class="hover-border"></div>
                        <div class="item-img">
                            <a href="javascript:;"><img src="img/blog/blog.jpg" alt="Blog"></a>
                        </div>
                        <div class="item-content">
                            <div class="item-author">
                                <div class="author-img">
                                    <img src="img/blog/author.jpg" alt="Author">
                                </div>
                                <span>Monica Peral</span>
                            </div>
                            <ul class="entry-meta">
                                <li><i class="far fa-clock"></i>July 15, 2019</li>
                                <li><i class="fas fa-folder-open"></i>Search Blocking</li>
                            </ul>
                            <h3 class="post-title">
                                <a href="javascript:;">Já pensou em escrever no espaço?</a>
                            </h3>
                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                Lorem Ipsum has been the industry...</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-12">
                    <div class="blog-box-layout1">
                        <div class="hover-border"></div>
                        <div class="item-img">
                            <a href="javascript:;"><img src="img/blog/blog1.jpg" alt="Blog"></a>
                        </div>
                        <div class="item-content">
                            <div class="item-author">
                                <div class="author-img">
                                    <img src="img/blog/author1.jpg" alt="Author">
                                </div>
                                <span>Mickle Jord</span>
                            </div>
                            <ul class="entry-meta">
                                <li><i class="far fa-clock"></i>July 15, 2019</li>
                                <li><i class="fas fa-folder-open"></i>Search Blocking</li>
                            </ul>
                            <h3 class="post-title">
                                <a href="javascript:;">A nova forma de ganhar dinheiro 1 + 1</a>
                            </h3>
                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                Lorem Ipsum has been the industry...</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-12 d-block d-md-none d-lg-block">
                    <div class="blog-box-layout1">
                        <div class="hover-border"></div>
                        <div class="item-img">
                            <a href="javascript:;"><img src="img/blog/blog2.jpg" alt="Blog"></a>
                        </div>
                        <div class="item-content">
                            <div class="item-author">
                                <div class="author-img">
                                    <img src="img/blog/author2.jpg" alt="Author">
                                </div>
                                <span>Alfred Molia</span>
                            </div>
                            <ul class="entry-meta">
                                <li><i class="far fa-clock"></i>July 15, 2019</li>
                                <li><i class="fas fa-folder-open"></i>Search Blocking</li>
                            </ul>
                            <h3 class="post-title">
                                <a href="javascript:;">Como medir a produtividade da sua equipa</a>
                            </h3>
                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                Lorem Ipsum has been the industry...</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- Blog Area End Here -->
</template>

<script>
    export default {
        name: "BlogComponent"
    }
</script>

<style scoped>

</style>