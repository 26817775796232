import Vue from 'vue'
import VueRouter from 'vue-router'
import IndexPage from '../pages/IndexPage'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'IndexPage',
    component: IndexPage
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../pages/AboutPage.vue')
  },
  {
    path: '/services',
    name: 'ServicesPage',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../pages/ServicesPage.vue')
  },
  {
    path: '/blog',
    name: 'BlogPage',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../pages/BlogPage.vue')
  },
  {
    path: '/quatro-formas-de-receber-um-novo-colaborador',
    name: 'BlogSinglePage',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../pages/blogs/BlogQuatroFormasDeReceberPage.vue')
  },
  {
    path: '/dicas-para-manter-a-produtividade',
    name: 'BlogDicasTrabalharAPartirDeCasaPage',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../pages/blogs/BlogDicasTrabalharAPartirDeCasaPage.vue')
  },
  {
    path: '/dicas-para-elaborar-um-optimo-cv',
    name: 'BlogDicasParaElaborarCVPage',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../pages/blogs/BlogDicasParaElaborarCVPage.vue')
  },
  {
    path: '/blog/:blogId',
    name: 'BlogDetails',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../pages/BlogDetailsPage.vue')
  },
  {
    path: '/contact',
    name: 'ContactPage',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../pages/ContactPage.vue')
  },
  {
    path: '/users',
    name: 'UsersPage',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../pages/UsersPage.vue')
  },
  {
    path: '/user/:id',
    name: 'UserDetailsPage',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../pages/UserDetailsPage.vue')
  }
]

const router = new VueRouter({
  routes,
})

export default router
