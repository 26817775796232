<template>
    <!-- Call To Action Start Here -->
    <section class="action-wrap-layout1 bg-common" data-bg-image="img/figure/seo-banner.jpg">
        <div class="container">
            <div class="heading-layout2">
                <h2>Your WebSite’s SEO info</h2>
                <p>Provide us with the following information youer website &amp; email and we'll email
                    you the SEO score of your website for free</p>
            </div>
            <div class="action-box-layout1">
                <form class="contact-form-box" id="contact-form">
                    <div class="row">
                        <div class="col-lg-5 col-12 form-group">
                            <input type="text" placeholder="Your Web URL..." class="form-control" name="name" data-error="Url field is required" required>
                        </div>
                        <div class="col-lg-5 col-12 form-group">
                            <input type="email" placeholder="Your E-mail Adress..." class="form-control" name="name" data-error="Name field is required" required>
                        </div>
                        <div class="col-lg-2 col-12 form-group">
                            <button type="submit" class="fw-btn-fill gradient-accent">SUBSCRIBE</button>
                        </div>
                    </div>
                    <div class="form-response"></div>
                </form>
            </div>
        </div>
    </section>
    <!-- Call To Action End Here -->
</template>

<script>
    export default {
        name: "CallAreaComponent"
    }
</script>

<style scoped>

</style>