<template>
    <div class="mean-bar">
        <div class="mobile-menu-nav-back">
            <a class="logo-mobile" href="index.html">
                <img src="img/logo-mobile.png" alt="logo" class="img-fluid"></a>
        </div>
        <a href="#nav" class="meanmenu-reveal" style="background:;color:;right:0;left:auto;">
            <span></span>
            <span></span>
            <span></span>
        </a>
        <nav class="mean-nav">
        <ul style="display: none;">
            <li>
                <a href="#">HOME</a>
                <ul class="dropdown-menu-col-1" style="display: none;">
                    <li>
                        <a href="index.html">Home 1</a>
                    </li>
                    <li>
                        <a href="index2.html">Home 2</a>
                    </li>
                    <li>
                        <a href="index3.html">Home 3</a>
                    </li>
                    <li>
                        <a href="index4.html">Home 4</a>
                    </li>
                </ul>
                <a class="mean-expand" href="#" style="font-size: 18px">+</a></li>
            <li>
                <a href="#">ABOUT</a>
                <ul class="dropdown-menu-col-1" style="display: none;">
                    <li>
                        <a href="about1.html">About 1</a>
                    </li>
                    <li>
                        <a href="about2.html">About 2</a>
                    </li>
                </ul>
                <a class="mean-expand" href="#" style="font-size: 18px">+</a></li>
            <li>
                <a href="#">CASE STUDY</a>
                <ul class="dropdown-menu-col-1" style="display: none;">
                    <li>
                        <a href="case-study1.html">Case Study 1</a>
                    </li>
                    <li>
                        <a href="case-study2.html">Case Study 2</a>
                    </li>
                    <li>
                        <a href="case-study3.html">Case Study 3</a>
                    </li>
                    <li>
                        <a href="single-case-study1.html">Single Case Study 1</a>
                    </li>
                    <li>
                        <a href="single-case-study2.html">Single Case Study 2</a>
                    </li>
                    <li>
                        <a href="single-case-study3.html">Single Case Study 3</a>
                    </li>
                </ul>
                <a class="mean-expand" href="#" style="font-size: 18px">+</a></li>
            <li>
                <a href="#">SERVICES</a>
                <ul class="dropdown-menu-col-1" style="display: none;">
                    <li>
                        <a href="service1.html">Service 1</a>
                    </li>
                    <li>
                        <a href="service2.html">Service 2</a>
                    </li>
                    <li>
                        <a href="single-service1.html">Single Service 1</a>
                    </li>
                    <li>
                        <a href="single-service2.html">Single Service 2</a>
                    </li>
                    <li>
                        <a href="single-service3.html">Single Service 3</a>
                    </li>
                </ul>
                <a class="mean-expand" href="#" style="font-size: 18px">+</a></li>
            <li class="possition-static hide-on-mobile-menu">
                <a href="#">PAGES</a>
                <div class="template-mega-menu">
                    <div class="container">
                        <div class="row">
                            <div class="col-3">
                                <div class="menu-ctg-title">Home</div>
                                <ul class="sub-menu" style="display: none;">
                                    <li>
                                        <a href="index.html">
                                            <i class="fas fa-home"></i>Home 1</a>
                                    </li>
                                    <li>
                                        <a href="index2.html">
                                            <i class="fas fa-home"></i>Home 2</a>
                                    </li>
                                    <li>
                                        <a href="index3.html">
                                            <i class="fas fa-home"></i>Home 3</a>
                                    </li>
                                    <li>
                                        <a href="index4.html">
                                            <i class="fas fa-home"></i>Home 4</a>
                                    </li>
                                </ul>
                                <a class="mean-expand" href="#" style="font-size: 18px">+</a></div>
                            <div class="col-3">
                                <div class="menu-ctg-title">Service</div>
                                <ul class="sub-menu" style="display: none;">
                                    <li>
                                        <a href="service1.html">
                                            <i class="fas fa-suitcase"></i>Service 1</a>
                                    </li>
                                    <li>
                                        <a href="service2.html">
                                            <i class="fas fa-suitcase"></i>Service 2</a>
                                    </li>
                                    <li>
                                        <a href="single-service1.html">
                                            <i class="fas fa-suitcase"></i>Single Service 1</a>
                                    </li>
                                    <li>
                                        <a href="single-service2.html">
                                            <i class="fas fa-suitcase"></i>Single Service 2</a>
                                    </li>
                                </ul>
                                <a class="mean-expand" href="#" style="font-size: 18px">+</a></div>
                            <div class="col-3">
                                <div class="menu-ctg-title">Case Study</div>
                                <ul class="sub-menu" style="display: none;">
                                    <li>
                                        <a href="case-study1.html">
                                            <i class="fas fa-handshake"></i>Case Study 1</a>
                                    </li>
                                    <li>
                                        <a href="case-study2.html">
                                            <i class="fas fa-handshake"></i>Case Study 2</a>
                                    </li>
                                    <li>
                                        <a href="case-study3.html">
                                            <i class="fas fa-handshake"></i>Case Study 3</a>
                                    </li>
                                    <li>
                                        <a href="single-case-study1.html">
                                            <i class="fas fa-handshake"></i>Single Case Study 1</a>
                                    </li>
                                </ul>
                                <a class="mean-expand" href="#" style="font-size: 18px">+</a></div>
                            <div class="col-3">
                                <div class="menu-ctg-title">Pages</div>
                                <ul class="sub-menu" style="display: none;">
                                    <li>
                                        <a href="blog1.html">
                                            <i class="fab fa-blogger"></i>Blog 1</a>
                                    </li>
                                    <li>
                                        <a href="blog2.html">
                                            <i class="fab fa-blogger"></i>Blog 2</a>
                                    </li>
                                    <li>
                                        <a href="contact.html">
                                            <i class="fas fa-envelope"></i>Contact</a>
                                    </li>
                                    <li>
                                        <a href="404.html">
                                            <i class="fas fa-exclamation-triangle"></i>404 Error</a>
                                    </li>
                                </ul>
                                <a class="mean-expand" href="#" style="font-size: 18px">+</a></div>
                        </div>
                    </div>
                </div>
            </li>
            <li class="hide-on-desktop-menu">
                <a href="#">Pages</a>
                <ul style="display: none;">
                    <li>
                        <a href="case-study1.html">Case Study</a>
                    </li>
                    <li>
                        <a href="single-blog1.html">Blog Details</a>
                    </li>
                    <li>
                        <a href="service1.html">Service</a>
                    </li>
                    <li>
                        <a href="404.html">404 Error</a>
                    </li>
                    <li>
                        <a href="contact.html">Contact</a>
                    </li>
                </ul>
                <a class="mean-expand" href="#" style="font-size: 18px">+</a></li>
            <li>
                <a href="#">BLOG</a>
                <ul class="dropdown-menu-col-1" style="display: none;">
                    <li>
                        <a href="blog1.html">Blog 1</a>
                    </li>
                    <li>
                        <a href="blog2.html">Blog 2</a>
                    </li>
                    <li>
                        <a href="single-blog1.html">Single Blog 1</a>
                    </li>
                </ul>
                <a class="mean-expand" href="#" style="font-size: 18px">+</a></li>
            <li class="mean-last">
                <a href="contact.html">CONTACT</a>
            </li>
        </ul>
    </nav></div>
</template>

<script>
    export default {
        name: "MeanBarComponent"
    }
</script>

<style scoped>

</style>