<template>
    <div>
        <slider-area-component />

        <!--<call-area-component />-->

        <service-component />

        <about-component />

        <!--<case-area-component />-->

        <!--<team-area-component />-->

        <banner-component />

        <!--<princing-component />-->

        <testemonial-component />

        <!--<blog-component />-->

        <brand-component />

        <contact-component />
    </div>
</template>

<script>
    import SliderAreaComponent from "../components/SliderAreaComponent";
    import CallAreaComponent from "../components/CallAreaComponent";
    import ServiceComponent from "../components/ServiceComponent";
    import AboutComponent from "../components/AboutComponent";
    import CaseAreaComponent from "../components/CaseAreaComponent";
    import TeamAreaComponent from "../components/TeamAreaComponent";
    import BannerComponent from "../components/BannerComponent";
    import PrincingComponent from "../components/PrincingComponent";
    import TestemonialComponent from "../components/TestemonialComponent";
    import BlogComponent from "../components/BlogComponent";
    import BrandComponent from "../components/BrandComponent";
    import ContactComponent from "../components/ContactComponent";
    export default {
        name: "IndexPage",
        components: {
            ContactComponent,
            BrandComponent,
            BlogComponent,
            TestemonialComponent,
            PrincingComponent,
            BannerComponent,
            TeamAreaComponent,
            CaseAreaComponent,
            AboutComponent,
            ServiceComponent,
            CallAreaComponent,
            SliderAreaComponent
        },
        mounted () {
            this.scrollToTop ()
        },
        methods: {
            scrollToTop () {
                var body = $("html, body");
                body.stop().animate({ scrollTop:0 }, 500, 'swing', function() {});
            }
        }
    }
</script>

<style scoped>

</style>