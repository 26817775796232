<template>
    <!-- Team Area Start Here -->
    <section class="team-wrap-layout2">
        <div class="container">
            <div class="heading-layout1">
                <h2>Nossa equipe</h2>
                <p>Good business leaders create a vision, articulate the vision, passionately own the vision,
                    and relentlessly drive it to and relentlessly drive it to and relentlessly drive it to completion completion
                    and relentlessly drive it to completion completion.</p>
            </div>
            <div class="rc-carousel nav-control-layout4" data-loop="true" data-items="30" data-margin="30"
                 data-autoplay="false" data-autoplay-timeout="4000" data-smart-speed="1000" data-dots="false"
                 data-nav="true" data-nav-speed="false" data-r-x-small="1" data-r-x-small-nav="true"
                 data-r-x-small-dots="false" data-r-x-medium="2" data-r-x-medium-nav="true" data-r-x-medium-dots="false"
                 data-r-small="2" data-r-small-nav="true" data-r-small-dots="false" data-r-medium="3"
                 data-r-medium-nav="true" data-r-medium-dots="false" data-r-large="4" data-r-large-nav="true"
                 data-r-large-dots="false" data-r-extra-large="4" data-r-extra-large-nav="true"
                 data-r-extra-large-dots="false">
                <div class="team-box-layout2">
                    <div class="item-img">
                        <img src="img/team/team6.jpg" alt="team">
                    </div>
                    <div class="item-content">
                        <h3 class="item-title"><a href="#">Julian Silva</a></h3>
                        <div class="item-subtitle">Content Writer</div>
                        <ul class="item-social">
                            <li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
                            <li><a href="#"><i class="fab fa-twitter"></i></a></li>
                            <li><a href="#"><i class="fab fa-linkedin-in"></i></a></li>
                            <li><a href="#"><i class="fab fa-google-plus-g"></i></a></li>
                            <li><a href="#"><i class="fab fa-pinterest"></i></a></li>
                        </ul>
                    </div>
                </div>
                <div class="team-box-layout2">
                    <div class="item-img">
                        <img src="img/team/team7.jpg" alt="team">
                    </div>
                    <div class="item-content">
                        <h3 class="item-title"><a href="#">Steven Paul</a></h3>
                        <div class="item-subtitle">Marketing</div>
                        <ul class="item-social">
                            <li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
                            <li><a href="#"><i class="fab fa-twitter"></i></a></li>
                            <li><a href="#"><i class="fab fa-linkedin-in"></i></a></li>
                            <li><a href="#"><i class="fab fa-google-plus-g"></i></a></li>
                            <li><a href="#"><i class="fab fa-pinterest"></i></a></li>
                        </ul>
                    </div>
                </div>
                <div class="team-box-layout2">
                    <div class="item-img">
                        <img src="img/team/team8.jpg" alt="team">
                    </div>
                    <div class="item-content">
                        <h3 class="item-title"><a href="#">Zinia Zara</a></h3>
                        <div class="item-subtitle">Seo Specialist</div>
                        <ul class="item-social">
                            <li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
                            <li><a href="#"><i class="fab fa-twitter"></i></a></li>
                            <li><a href="#"><i class="fab fa-linkedin-in"></i></a></li>
                            <li><a href="#"><i class="fab fa-google-plus-g"></i></a></li>
                            <li><a href="#"><i class="fab fa-pinterest"></i></a></li>
                        </ul>
                    </div>
                </div>
                <div class="team-box-layout2">
                    <div class="item-img">
                        <img src="img/team/team9.jpg" alt="team">
                    </div>
                    <div class="item-content">
                        <h3 class="item-title"><a href="#">Johnatan John</a></h3>
                        <div class="item-subtitle">Researcher</div>
                        <ul class="item-social">
                            <li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
                            <li><a href="#"><i class="fab fa-twitter"></i></a></li>
                            <li><a href="#"><i class="fab fa-linkedin-in"></i></a></li>
                            <li><a href="#"><i class="fab fa-google-plus-g"></i></a></li>
                            <li><a href="#"><i class="fab fa-pinterest"></i></a></li>
                        </ul>
                    </div>
                </div>
                <div class="team-box-layout2">
                    <div class="item-img">
                        <img src="img/team/team10.jpg" alt="team">
                    </div>
                    <div class="item-content">
                        <h3 class="item-title"><a href="#">Maria Silva</a></h3>
                        <div class="item-subtitle">Analyst</div>
                        <ul class="item-social">
                            <li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
                            <li><a href="#"><i class="fab fa-twitter"></i></a></li>
                            <li><a href="#"><i class="fab fa-linkedin-in"></i></a></li>
                            <li><a href="#"><i class="fab fa-google-plus-g"></i></a></li>
                            <li><a href="#"><i class="fab fa-pinterest"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- Team Area End Here -->
</template>

<script>
    export default {
        name: "TeamAreaComponent",
        mounted() {
            /*-------------------------------------
            Carousel slider initiation
            -------------------------------------*/
            $(".rc-carousel").each(function () {
                var carousel = $(this),
                    loop = carousel.data("loop"),
                    Canimate = carousel.data("animate"),
                    items = carousel.data("items"),
                    margin = carousel.data("margin"),
                    stagePadding = carousel.data("stage-padding"),
                    autoplay = carousel.data("autoplay"),
                    autoplayTimeout = carousel.data("autoplay-timeout"),
                    smartSpeed = carousel.data("smart-speed"),
                    dots = carousel.data("dots"),
                    nav = carousel.data("nav"),
                    navSpeed = carousel.data("nav-speed"),
                    rXsmall = carousel.data("r-x-small"),
                    rXsmallNav = carousel.data("r-x-small-nav"),
                    rXsmallDots = carousel.data("r-x-small-dots"),
                    rXmedium = carousel.data("r-x-medium"),
                    rXmediumNav = carousel.data("r-x-medium-nav"),
                    rXmediumDots = carousel.data("r-x-medium-dots"),
                    rSmall = carousel.data("r-small"),
                    rSmallNav = carousel.data("r-small-nav"),
                    rSmallDots = carousel.data("r-small-dots"),
                    rMedium = carousel.data("r-medium"),
                    rMediumNav = carousel.data("r-medium-nav"),
                    rMediumDots = carousel.data("r-medium-dots"),
                    rLarge = carousel.data("r-large"),
                    rLargeNav = carousel.data("r-large-nav"),
                    rLargeDots = carousel.data("r-large-dots"),
                    rExtraLarge = carousel.data("r-extra-large"),
                    rExtraLargeNav = carousel.data("r-extra-large-nav"),
                    rExtraLargeDots = carousel.data("r-extra-large-dots"),
                    center = carousel.data("center"),
                    custom_nav = carousel.data("custom-nav") || "";
                carousel.addClass('owl-carousel');
                var owl = carousel.owlCarousel({
                    loop: loop ? true : false,
                    animateOut: Canimate,
                    items: items ? items : 1,
                    lazyLoad: true,
                    margin: margin ? margin : 0,
                    autoplay: autoplay ? true : false,
                    autoplayTimeout: autoplayTimeout ? autoplayTimeout : 1000,
                    smartSpeed: smartSpeed ? smartSpeed : 250,
                    dots: dots ? true : false,
                    nav: nav ? true : false,
                    navText: [
                        '<i class="fa fa-angle-left" aria-hidden="true"></i>',
                        '<i class="fa fa-angle-right" aria-hidden="true"></i>'
                    ],
                    navSpeed: navSpeed ? true : false,
                    center: center ? true : false,
                    responsiveClass: true,
                    responsive: {
                        0: {
                            items: rXsmall ? rXsmall : 1,
                            nav: rXsmallNav ? true : false,
                            dots: rXsmallDots ? true : false
                        },
                        576: {
                            items: rXmedium ? rXmedium : 2,
                            nav: rXmediumNav ? true : false,
                            dots: rXmediumDots ? true : false
                        },
                        768: {
                            items: rSmall ? rSmall : 3,
                            nav: rSmallNav ? true : false,
                            dots: rSmallDots ? true : false
                        },
                        992: {
                            items: rMedium ? rMedium : 4,
                            nav: rMediumNav ? true : false,
                            dots: rMediumDots ? true : false
                        },
                        1200: {
                            items: rLarge ? rLarge : 5,
                            nav: rLargeNav ? true : false,
                            dots: rLargeDots ? true : false
                        },
                        1240: {
                            items: rExtraLarge ? rExtraLarge : 5,
                            nav: rExtraLargeNav ? true : false,
                            dots: rExtraLargeDots ? true : false
                        }
                    },
                });

                if (custom_nav) {
                    var nav = $(custom_nav),
                        nav_next = $(".rt-next", nav),
                        nav_prev = $(".rt-prev", nav);

                    nav_next.on("click", function (e) {
                        e.preventDefault();
                        owl.trigger('next.owl.carousel');
                        return false;
                    });

                    nav_prev.on("click", function (e) {
                        e.preventDefault();
                        owl.trigger('prev.owl.carousel');
                        return false;
                    });
                }
            });
        }
    }
</script>

<style scoped>

</style>