<template>
    <!-- Search Box Start Here -->
    <div id="header-search" class="header-search">
        <button type="button" class="close">×</button>
        <form class="header-search-form">
            <input type="search" value="" placeholder="Type here........" />
            <button type="submit" class="search-btn">
                <i class="flaticon-magnifying-glass"></i>
            </button>
        </form>
    </div>
    <!-- Search Box End Here -->
</template>

<script>
    export default {
        name: "HeaderSearchComponent"
    }
</script>

<style scoped>

</style>