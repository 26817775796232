import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'

// firebase init - add your own config here
const firebaseConfig = {
    apiKey: "AIzaSyCPWD8wU1hGRbzCt5hGxlzTGVPBpbbZLMY",
    authDomain: "ehybridapp.firebaseapp.com",
    databaseURL: "https://ehybridapp.firebaseio.com",
    projectId: "ehybridapp",
    storageBucket: "ehybridapp.appspot.com",
    messagingSenderId: "549857421279",
    appId: "1:549857421279:web:7d95a571e96853080ec66a",
    measurementId: "G-BNG72V9E7K"
}

firebase.initializeApp(firebaseConfig)

// utils
const db = firebase.firestore()

// collection references
const dbNorbUserContact = db.collection('norbUserContact')

// export utils/refs
export {
	dbNorbUserContact
}