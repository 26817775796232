import { render, staticRenderFns } from "./CallAreaComponent.vue?vue&type=template&id=b577c97c&scoped=true&"
import script from "./CallAreaComponent.vue?vue&type=script&lang=js&"
export * from "./CallAreaComponent.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b577c97c",
  null
  
)

export default component.exports