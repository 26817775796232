<template>
    <!-- About Area Start Here -->
    <section class="about-wrap-layout1 bg-common" data-bg-image="img/about/carved-svg.svg">
        <div class="about-box-layout1">
            <div class="single-item about-left-item">
                <div class="item-img">
                    <img src="img/about/about.png" alt="About">
                </div>
            </div>
            <div class="single-item about-right-item">
                <div class="item-content">
                    <h2 class="item-title">
                        Pretende Contratar um Profissional de Excelência para a sua Empresa?
                    </h2>
                    <p>
                        Se pretende seleccionar colaboradores que tenham a capacidade de transformar a sua organização, a
                        Norb Consulting dá-lhe acesso aos melhores talentos do mercado em Moçambique. Os nossos
                        Consultores recrutam para vários sectores de actividade e fazem uso do seu vasto conhecimento de
                        mercado para encontrar o candidato certo para a função certa. Estudamos com o cliente o prazo e os
                        métodos para a contratação do perfil mais indicado, cientes que a eficácia de um serviço é fruto de
                        procedimentos tempestivos e de respostas rápidas. Cuidamos com o máximo profissionalismo a
                        selecção das candidaturas e o feedback dos resultados atingidos.
                        Venha até nós. Contacte-nos para mais informações.
                    </p>
                    <b>Garantias</b>
                    <ul class="list-item">
                        <li><i class="fas fa-angle-right"></i>
                            Substituição de profissionais em caso de falta de acordo ou de interrupção do contrato de
                            trabalho durante os primeiros meses;
                        </li>
                        <li><i class="fas fa-angle-right"></i>
                            Assistência Constante com vista a oferecer um serviço de alta qualidade.
                        </li>
                        <!--<li><i class="fas fa-angle-right"></i>Best Solution For Your Better Web Marketing.</li>-->
                    </ul>
                    <router-link to="/about" class="btn-fill gradient-accent">Saber mais<i class="fas fa-arrow-circle-right"></i></router-link>
                </div>
            </div>
        </div>
    </section>
    <!-- About Area End Here -->
</template>

<script>
    export default {
        name: "AboutComponent"
    }
</script>

<style scoped>

</style>