<template>
  <div>
    <div id="wrapper" class="wrapper" :class="{'open' : canToggleOffCanvas}">

      <header-component
        :canHideTopSmallBar="true"
        @canToggleOffCanvas="canToggleOffCanvas = !canToggleOffCanvas"
      />

      <header-component-mobile
        @canToggleOffCanvas="canToggleOffCanvas = !canToggleOffCanvas"
      ></header-component-mobile>

      <!--<mean-bar-component />-->

      <div>

        <router-view/>

      </div>

      <footer-component />

      <header-search-component />


      <div class="offcanvas-mask" v-if="canToggleOffCanvas">
        <div class="offcanvas-menu-wrap" id="offcanvas-wrap" data-position="right" style="right: 300px">

          <div @click="canToggleOffCanvas = !canToggleOffCanvas" class="close-btn offcanvas-close"><i class="fas fa-long-arrow-alt-right"></i></div>

          <canvas-component />

        </div>
      </div>


      <!--<canvas-component />-->

    </div>
  </div>
</template>
<style>

</style>
<script>
    import HeaderComponent from "./components/HeaderComponent";
    import HeaderComponentMobile from "./components/HeaderComponentMobile";
    import CanvasComponent from "./components/CanvasComponent";
    import HeaderSearchComponent from "./components/HeaderSearchComponent";
    import FooterComponent from "./components/FooterComponent";
    import ContactComponent from "./components/ContactComponent";
    import BrandComponent from "./components/BrandComponent";
    import BlogComponent from "./components/BlogComponent";
    import TestemonialComponent from "./components/TestemonialComponent";
    import PrincingComponent from "./components/PrincingComponent";
    import BannerComponent from "./components/BannerComponent";
    import TeamAreaComponent from "./components/TeamAreaComponent";
    import CaseAreaComponent from "./components/CaseAreaComponent";
    import AboutComponent from "./components/AboutComponent";
    import ServiceComponent from "./components/ServiceComponent";
    import CallAreaComponent from "./components/CallAreaComponent";
    import SliderAreaComponent from "./components/SliderAreaComponent";
    import MeanBarComponent from "./components/base/MeanBarComponent";
    export default {
        components: {
            MeanBarComponent,
            SliderAreaComponent,
            CallAreaComponent,
            ServiceComponent,
            AboutComponent,
            CaseAreaComponent,
            TeamAreaComponent,
            BannerComponent,
            PrincingComponent,
            TestemonialComponent,
            BlogComponent,
            BrandComponent,
            ContactComponent, FooterComponent, HeaderSearchComponent, CanvasComponent, HeaderComponent,
            HeaderComponentMobile
        },
        data () {
            return {
                canToggleOffCanvas: false,
            }
        }

    }
</script>