<template>
    <div class="offcanvas-content">
        <div class="offcanvas-logo">
            <a href="/">
                <img src="img/logo.png" alt="logo" style="width: 100px;height: 100px"> NORB
            </a>
        </div>
        <ul class="offcanvas-menu">
            <li class="nav-item">
                <router-link to="/">INÍCIO</router-link>
            </li>
            <li class="nav-item">
                <router-link to="/services">SERVIÇOS</router-link>
            </li>
            <li class="nav-item">
                <router-link to="/blog">BLOG</router-link>
            </li>
            <li class="nav-item">
                <router-link to="/contact">CONTACTOS</router-link>
            </li>
        </ul>
        <div class="offcanvas-footer">
            <div class="item-title">Siga-nos</div>
            <ul class="offcanvas-social">
                <li><a href="https://web.facebook.com/Norb-Consulting-867907223357862" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                <li><a href="https://www.instagram.com/norb.co.mz/?hl=pt" target="_blank"><i class="fab fa-instagram"></i></a></li>
                <li><a href="https://www.linkedin.com/company/norb-consulting/" target="_blank"><i class="fab fa-linkedin-in"></i></a></li>
            </ul>
            <!--<ul class="offcanvas-social">-->
                <!--<li><a href="#"><i class="fab fa-facebook-f"></i></a></li>-->
                <!--<li><a href="#"><i class="fab fa-twitter"></i></a></li>-->
                <!--<li><a href="#"><i class="fab fa-linkedin-in"></i></a></li>-->
                <!--<li><a href="#"><i class="fab fa-google-plus-g"></i></a></li>-->
                <!--<li><a href="#"><i class="fab fa-pinterest"></i></a></li>-->
                <!--<li><a href="#"><i class="fas fa-rss"></i></a></li>-->
            <!--</ul>-->
        </div>
    </div>
</template>

<script>
    export default {
        name: "CanvasComponent",
    }
</script>

<style scoped>

</style>